import { useLoaderData, useNavigate, useNavigation } from 'react-router-dom';
import ResultCard from './ResultCard';
import { Teacher } from '../../types/Teacher';
import { Institution } from '../../types/Institution';
import { Course } from '../../types/Course';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useState } from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Button, ButtonColor, ButtonSize } from '../Button';
import { ButtonPaddingSize } from '../Button/Button.types';
import { trackClickButtonEvent } from '../../utils/functions/trackEvent';
import './Results.css';
import Loader from '../Loader';

type OptionType = {
  value: string;
  label: string;
  sortingFunction: ((a: Teacher, b: Teacher) => number) | undefined;
};

const options: OptionType[] = [
  { label: 'Ordenar por', value: 'default', sortingFunction: undefined },
  {
    value: 'featured',
    label: 'Destacados',
    sortingFunction: undefined
  },
  {
    value: 'higherPrice',
    label: 'Mayor precio',
    sortingFunction: (a: Teacher, b: Teacher) => b.price_per_hour - a.price_per_hour
  },
  {
    value: 'lowerPrice',
    label: 'Menor Precio',
    sortingFunction: (a: Teacher, b: Teacher) => a.price_per_hour - b.price_per_hour
  },
  {
    value: 'classes',
    label: 'Clases dictadas',
    sortingFunction: (a: Teacher, b: Teacher) =>
      (b.course_dictated_classes || 0) - (a.course_dictated_classes || 0)
  },
  {
    value: 'rate',
    label: 'Mejor puntuados',
    sortingFunction: (a: Teacher, b: Teacher) => {
      const weightedAverageA = (a?.rate?.average || 0) * Math.sqrt(a?.rate?.quantity || 0);
      const weightedAverageB = (b?.rate?.average || 0) * Math.sqrt(b?.rate?.quantity || 0);

      if (weightedAverageA > weightedAverageB) {
        return -1;
      } else if (weightedAverageA < weightedAverageB) {
        return 1;
      } else {
        return 0;
      }
    }
  }
];

const Results = () => {
  const { teachers, course, institution } = useLoaderData() as {
    teachers: Teacher[];
    institution: Institution;
    course: Course;
  };
  const navigate = useNavigate();
  const [teachersSorted, setTeachersSorted] = useState<Teacher[]>([...teachers]);

  const navigation = useNavigation();
  const resultsCountText = teachers.length === 1 ? 'resultado' : 'resultados';

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const open = Boolean(anchorEl);

  const handleChange = (option: any) => {
    if (option.value === 'featured') {
      setTeachersSorted([...teachers]);
    } else {
      setTeachersSorted(teachersSorted.sort(option?.sortingFunction!));
    }
  };
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    handleChange(options[index]);
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTeacherRequestWhenNoResults = () => {
    trackClickButtonEvent('teacher_request_when_no_results');
    navigate(`/help/request?institutionId=${institution.value}&courseId=${course.value}`);
  };

  return (
    <div className="results">
      {navigation.state === 'loading' ? (
        <Loader />
      ) : (
        <div className="teachers">
          {teachers.length === 0 ? (
            <div>
              Lo sentimos, en este momento no tenemos registrado en nuestro sistema ningún profesor
              para {course.label} - {institution.value.toUpperCase()}.<br /> <br /> ¡Tranquilo! de
              todas formas podes pedirnos tu profe aca:
              <br />
              <br />
              <Button
                color={ButtonColor.SECONDARY}
                size={ButtonSize.MD}
                paddingSize={ButtonPaddingSize.MD}
                className="teacherRequestButton"
                onClick={handleTeacherRequestWhenNoResults}>
                Pedir profesor
              </Button>
              <br />y nos pondremos en contacto para encontrarte tu profe ideal.
            </div>
          ) : (
            <div className="resultsInfoSection">
              <div className="resultsCount">
                Mostrando {teachers.length} {resultsCountText} para {course.label} -{' '}
                {institution.value.toUpperCase()}
              </div>
              <div className="sortBySection">
                <div>
                  <List
                    component="nav"
                    aria-label="Device settings"
                    sx={{ bgcolor: 'transparent' }}>
                    <ListItemButton
                      id="lock-button"
                      aria-haspopup="listbox"
                      aria-controls="lock-menu"
                      sx={{ margin: '4px', padding: 0 }}
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClickListItem}>
                      <ListItemIcon sx={{ marginRight: '1%', minWidth: 'auto' }}>
                        <SwapVertIcon />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontFamily: 'Open Sans', fontSize: '0.9rem' }}
                        sx={{ whiteSpace: 'nowrap' }}
                        primary={options[selectedIndex].label}
                      />
                    </ListItemButton>
                  </List>
                  <Menu
                    id="lock-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'lock-button',
                      role: 'listbox'
                    }}>
                    {options.map((option, index) => (
                      <MenuItem
                        key={option.value}
                        disabled={index === 0}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              </div>
            </div>
          )}

          <div className="resultsContent">
            {teachersSorted.map((teacher: Teacher) => {
              return <ResultCard key={`teacher-${teacher.external_id}`} result={teacher} />;
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Results;
