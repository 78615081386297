import ClipLoader from 'react-spinners/ClipLoader';
import './Loader.css';

const Loader = () => {
  return (
    <div className="loaderSpinner">
      <span>Aguarde un instante</span>
      <ClipLoader color="#184b76" />
    </div>
  );
};

export default Loader;
