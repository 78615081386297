import React, { useState } from 'react';
import {
  Typography,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Course } from '../../../../types/Course';
import { Institution } from '../../../../types/Institution';
import TextField from '@mui/material/TextField';
import './EditCoursesSelector.css';

interface CoursesSelectorProps {
  institutions: Institution[];
  coursesByInstitution: Map<string, Course[]>;
  dictatedCourses: Course[];
  onChange: (updatedCourses: Course[]) => void;
}

const blacklistedInstitutions = [
  'itba',
  'fceAustral',
  'fcbiomedAustral',
  'fiAustral',
  'ingresoAustral',
  'utdt',
  'ingresoUtdt',
  'udesa',
  'ingresoUdesa'
];

const EditCoursesSelector: React.FC<CoursesSelectorProps> = ({
  institutions,
  coursesByInstitution,
  dictatedCourses,
  onChange
}) => {
  const [updatedCourses, setUpdatedCourses] = useState<Course[]>(dictatedCourses);

  const handleCourseSelection = (course: Course, isSelected: boolean) => {
    let newCourses: Course[];

    if (isSelected) {
      newCourses = [...updatedCourses.filter((c) => c.value !== course.value), course];
    } else {
      newCourses = updatedCourses.filter((c) => c.value !== course.value);
    }

    setUpdatedCourses(newCourses);
    onChange(newCourses);
  };

  return (
    <div>
      {institutions.map((institution) => (
        <Accordion
          key={institution.value}
          disabled={blacklistedInstitutions.includes(institution.value)}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {blacklistedInstitutions.includes(institution.value) ? (
              <Typography>
                {institution.label} <br />
                Si queres hacer cambios de materias contactanos!
              </Typography>
            ) : (
              <Typography>{institution.label}</Typography>
            )}
          </AccordionSummary>
          <AccordionDetails>
            {coursesByInstitution.get(institution.value)?.map((course) => (
              <Stack
                key={course.value}
                direction="column"
                alignItems="center"
                spacing={2}
                sx={{
                  padding: '10px 0',
                  borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
                }}>
                <div className="accordionCourseName">
                  <Checkbox
                    checked={updatedCourses.some((c) => c.value === course.value) || false}
                    onChange={(e) => handleCourseSelection(course, e.target.checked)}
                  />
                  <Typography
                    sx={{
                      flex: 1,
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis'
                    }}
                    title={course.label}>
                    {course.label}
                  </Typography>
                </div>
                {updatedCourses.some((c) => c.value === course.value) && (
                  <TextField
                    placeholder="Notas adicionales (opcional)"
                    variant="outlined"
                    fullWidth
                    size="small"
                    defaultValue={updatedCourses.find((c) => c.value === course.value)?.notes || ''}
                    onChange={(e) =>
                      handleCourseSelection({ ...course, notes: e.target.value }, true)
                    }
                  />
                )}
              </Stack>
            )) || <Typography>No hay materias disponibles para esta institución.</Typography>}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default EditCoursesSelector;
